import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { flatCasinoGames } from "./games.utils";

export interface GamesState {
  isFetching?: boolean;
  isFetchingWidget?: boolean;
  casino?: GamesListResponsePayload;
  casinoFlat?: ProviderGame[];
  casinoLiveFlat?: ProviderGame[];
  casinoLive?: GamesListResponsePayload;
  virtual?: GamesListResponsePayload;
  highlights?: HighlightsResponsePayload;
  game?: GamesStartResponsePayload;
  iFrameState: boolean;
  casinoFilterState: string;
  casinoGamesSearchValue: string;
  isSearchMenuOpen: boolean;
  isProvidersMenuOpen: boolean;
  casinoSelectedProvidersNames: string[];
  casinoSelectedProvidersGames: any;
  casinoGamesToShow: number;
  totalCasinoGames?: number;
  casinoGamesFilter: CasinoGamesFilter;
  displayMobileProviders: boolean;
  mobileSearchOverlay: boolean;
}
export interface Provider {
  id?: number;
  name?: string;
  games?: ProviderGame[];
  label?: string;
  icon: string;
}

export interface CasinoGamesFilter {
  filter: string;
  sort?: number;
}

export interface CasinoGamesFilterPayload {
  name: string;
  value: string | number;
}

export interface ProviderGame {
  category_id?: string;
  auto_start?: boolean;
  enabled?: boolean;
  icon?: string;
  id?: number;
  label: string;
  special?: boolean;
}

export interface GamesStartResponsePayload {
  content: string;
  mode: string;
  name: string;
  size: string;
  type: string;
}

export interface GamesListResponsePayload {
  providers?: Provider[];
}
export interface HighlightsResponsePayload {
  banners?: ProviderGame[];
  specials?: ProviderGame[];
}
const initialState: GamesState = {
  isFetching: false,
  casino: undefined,
  casinoLive: undefined,
  casinoFlat: undefined,
  virtual: undefined,
  game: undefined,
  iFrameState: false,
  casinoFilterState: "allgames",
  casinoGamesSearchValue: "",
  isSearchMenuOpen: false,
  isProvidersMenuOpen: false,
  casinoSelectedProvidersNames: [],
  casinoSelectedProvidersGames: undefined,
  casinoGamesToShow: 56,
  totalCasinoGames: undefined,
  casinoGamesFilter: {
    filter: "all",
    sort: undefined,
  },
  displayMobileProviders: false,
  mobileSearchOverlay: false,
};

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    getHighlightsRequested: (state: GamesState) => {
      state.isFetching = true;
    },
    getHighlightsSucceded: (
      state: GamesState,
      action: PayloadAction<HighlightsResponsePayload>
    ) => {
      state.isFetching = false;
      state.highlights = action.payload;
    },
    getHighlightsFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getCasinoSucceded: (
      state: GamesState,
      action: PayloadAction<GamesListResponsePayload>
    ) => {
      state.casino = action.payload;
      state.casinoFlat = flatCasinoGames(action.payload);
      state.isFetching = false;
    },
    getCasinoRequested: (state: GamesState) => {
      state.isFetching = true;
    },

    getCasinoFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getCasinoLiveRequested: (state: GamesState) => {
      state.isFetching = true;
    },
    getCasinoLiveSucceded: (
      state: GamesState,
      action: PayloadAction<GamesListResponsePayload>
    ) => {
      state.casinoLive = action.payload;
      state.casinoLiveFlat = flatCasinoGames(action.payload);

      state.isFetching = false;
    },
    getCasinoLiveFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getVirtualRequested: (state: GamesState) => {
      state.isFetching = true;
    },
    getVirtualSucceded: (
      state: GamesState,
      action: PayloadAction<GamesListResponsePayload>
    ) => {
      state.virtual = action.payload;
      state.isFetching = false;
    },
    getVirtualFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getGameRequested: (state: GamesState, action: PayloadAction<number>) => {
      state.isFetching = true;
      action;
    },
    getGameSucceded: (
      state: GamesState,
      action: PayloadAction<GamesStartResponsePayload>
    ) => {
      state.game = action.payload;
      if (action.payload.mode !== "window") {
        state.iFrameState = true;
      }
      state.isFetching = false;
    },
    setIFrameState: (state: GamesState, action: PayloadAction<boolean>) => {
      state.iFrameState = action.payload;
    },
    clearIFrameState: (state: GamesState) => {
      state.iFrameState = false;
    },
    clearGameLink: (state: GamesState) => {
      state.game = undefined;
    },
    setCasinoFilterState: (
      state: GamesState,
      action: PayloadAction<string>
    ) => {
      state.casinoFilterState = action.payload;
    },
    saveGamesSearchValue: (
      state: GamesState,
      action: PayloadAction<string>
    ) => {
      state.casinoGamesSearchValue = action.payload;
    },
    setSearchMenu: (state: GamesState, action: PayloadAction<boolean>) => {
      state.isSearchMenuOpen = action.payload;
    },
    setProvidersMenu: (state: GamesState, action: PayloadAction<boolean>) => {
      state.isProvidersMenuOpen = action.payload;
    },
    toggleGamesToShow: (state: GamesState) => {
      state.casinoGamesToShow += 56;
    },
    saveSelectedProviders: (
      state: GamesState,
      action: PayloadAction<Provider>
    ) => {
      const provider = action.payload;
      if (provider.name) {
        // SET SELECTED PROVIDER NAME TO SELECTED PROVIDERS NAMES ARRAY
        if (state.casinoSelectedProvidersNames.includes(provider.name)) {
          state.casinoSelectedProvidersNames =
            state.casinoSelectedProvidersNames.filter(
              (el) => el !== provider.name
            );
        } else {
          state.casinoSelectedProvidersNames = [
            provider.name,
            ...state.casinoSelectedProvidersNames,
          ];
        }
        // SET SELECTED PROVIDER GAMES TO PROVIDERS FILTERED GAMES
        if (
          state.casinoSelectedProvidersGames &&
          Object.keys(state.casinoSelectedProvidersGames).includes(
            provider.name
          )
        ) {
          const updatedGames = { ...state.casinoSelectedProvidersGames };
          delete updatedGames[provider.name];
          state.casinoSelectedProvidersGames = updatedGames;
        } else {
          state.casinoSelectedProvidersGames = {
            [provider.name]: provider.games,
            ...state.casinoSelectedProvidersGames,
          };
        }
      }
      state.casinoGamesToShow = 56;
    },
    changeCasinoFilter: (
      state: GamesState,
      action: PayloadAction<CasinoGamesFilterPayload>
    ) => {
      if (
        action.payload.name === "sort" &&
        state.casinoGamesFilter.sort === action.payload.value
      ) {
        state.casinoGamesFilter = {
          ...state.casinoGamesFilter,
          sort: undefined,
        };
      } else {
        state.casinoGamesFilter = {
          ...state.casinoGamesFilter,
          [action.payload.name]: action.payload.value,
        };
      }
    },
    updateTotalGames: (state: GamesState, action: PayloadAction<number>) => {
      state.totalCasinoGames = action.payload;
    },
    toggleDisplayProvidersMobile: (
      state: GamesState,
      action: PayloadAction<boolean>
    ) => {
      state.displayMobileProviders = action.payload;
    },
    toggleMobileSearchOverlay: (
      state: GamesState,
      action: PayloadAction<boolean>
    ) => {
      state.mobileSearchOverlay = action.payload;
    },
    clearHighlights: (state: GamesState) => {
      state.highlights = undefined;
    },
  },
});

export const {
  getHighlightsRequested,
  getHighlightsFailed,
  getHighlightsSucceded,
  getCasinoRequested,
  getCasinoSucceded,
  getCasinoFailed,
  getCasinoLiveRequested,
  getCasinoLiveSucceded,
  getCasinoLiveFailed,
  getVirtualRequested,
  getVirtualSucceded,
  getVirtualFailed,
  getGameRequested,
  getGameSucceded,
  clearGameLink,
  clearIFrameState,
  setIFrameState,
  setCasinoFilterState,
  saveGamesSearchValue,
  setSearchMenu,
  setProvidersMenu,
  clearHighlights,
  toggleGamesToShow,
  saveSelectedProviders,
  changeCasinoFilter,
  updateTotalGames,
  toggleDisplayProvidersMobile,
  toggleMobileSearchOverlay,
} = gamesSlice.actions;

export default gamesSlice.reducer;
