import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Currency } from "../config/configSlice";
import {
  convertChildren,
  convertRevenueChildren,
  editWallet,
  moveFundsWallets,
} from "./wallet.utils";
import { FilterElement } from "components/wallet/TransactionsTable";
import { RevenueDetailFilter, RevenueFilterElement } from "../../constants";
export interface WalletState {
  isFetching: boolean;
  isFetchingWallet: boolean;
  isFetchingChildren: boolean;
  isFetchingChildrenUpdate: boolean;
  isFetchingWalletToEdit: boolean;
  isFetchingAscendants: boolean;
  isFetchingSkinTrx: boolean;
  isFetchingNetworkTrx: boolean;
  isFetchingReceipt: boolean;
  isFetchingSkinBetting: boolean;
  isFetchingNetworkBetting: boolean;
  isFetchingProvisionalPlan: boolean;
  isFetchingRevenues: boolean;
  isFetchingRevenuesChildren: boolean;
  isFetchingMoveFunds: boolean;
  isFetchingWalletBonus: boolean;
  isFetchingWalletMove: boolean;

  networkWallet?: Wallet;
  networkWalletChildren: Record<string, Wallet[]>;
  selectedWalletTypeId?: number;
  walletId?: number;
  walletToEdit?: PayloadData;
  ancestors?: Wallet[];

  skinTransactions?: Transactions;
  networkTransactions?: Transactions;
  receipt?: Receipt;
  skinBetting?: Betting;
  networkBetting?: Betting;
  provisional_plan?: ProvisionalPlan;
  revenues?: Revenues;
  revenuesChildren: Record<string, Revenues[]>;

  bettingWalletChildren: Record<string, Wallet[]>;
  bettingWalletId?: number;
  searchResults?: Wallet[];
  isBalanceRechargeVisible: boolean;

  page: number | undefined;
  rowsPerPage: number | undefined;
  filters: BetFilter | undefined;

  playedCouponWalletId?: number;

  isDesktopLayout: boolean;
  affectedWallets?: any;
  loginHistory?: LoginHistoryType;
  walletBonus?: WalletBonus;

  revenueDetails?: RevenueDetails[];
}
export interface Wallet {
  id: number;
  name: string;
  overdraft: number;
  balance: number;
  children_overdraft: number;
  children_balance: number;
  children_availability: number;
  children_count: number;
  enabled: boolean;
  currency_id: number;
  currency: Currency;
  parent_id: number;
  availability: number;
  wallet_type?: WalletType;
  address: string;
  city_id: number;
  fee: number;
  total: number;
  vat: string;
  cap: string;
  provigional_plan?: ProvidgionalType;
  wallet_type_id: number;
  campaign: boolean;
  breadcum: string;
}

export interface WalletBonus {
  name: string;
  type: string;
  enabled: boolean;
  bonus_amount: number;
  bonus_target: number;
  bonus_value: number;
  created_at: string;
  updated_at: string;
  start_date: string;
  end_date: string;
  available: boolean;
}

export interface WalletType {
  id?: number;
  label?: string;
  icon?: string;
  multiuser?: number;
  backoffice?: number;
  provigional_plan: boolean;
  can_contain_wallettype?: number[];
  can_perform_transactiontype?: number[];
  enabled?: boolean;
  permissions?: Array<string>;
  children: boolean;
  name?: string;
}
export interface ProvidgionalType {
  id?: number;
  name?: string;
  label?: string;
}

export interface WalletTypeForCoupon {
  id: number;
  label: string;
  icon: string;
  multiuser: boolean;
  backoffice: boolean;
  provigional_plan: boolean;
  enabled: boolean;
  children: boolean;
}
export interface Attributes {
  add?: string[];
  edit?: string[];
  wallet: boolean;
}
interface KeysNewData {
  [key: string]: UserInfo | WalletInformation;
}
export interface PayloadData extends KeysNewData {
  user: UserInfo;
  wallet: WalletInformation;
}
export interface LoginHistoryType {
  id: number;
  records: recordsLoginHistory[];
}
export interface recordsLoginHistory {
  login_date: Date;
  login_ip: number;
}

export interface UserPayloadData {
  user: UserInfo;
}
export interface WalletInformation {
  wallet_type_id?: number;
}

export interface UserInfo {
  username: string;
  password: string;
  c_password: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  enabled?: boolean;
  id?: number;
  wallet_id?: number;
  promo_code?: string;
  password_change: boolean;
}
export interface Transactions {
  records: TransactionData[];
  recordsFiltered: number;
  recordsTotal: number;
  totals: Total;
  page: Page;
}

export interface Total {
  balancedeposit: number;
  balancewithdrawal: number;
  overdraftdeposit: number;
  overdraftwithdrawal: number;
  balancefinal: number;
  overdraftfinal: number;
}

export interface Page {
  balancedeposit: number;
  balancewithdrawal: number;
  overdraftdeposit: number;
  overdraftwithdrawal: number;
  balancefinal: number;
  overdraftfinal: number;
}

export interface Betting {
  records: BettingData[];
  recordsFiltered: number;
  recordsTotal: number;
  totals: Total;
  page: Page;
}

export interface BetFilter {
  wallet_id: string;
  coupon_type_id: string | number;
  coupon_state_id: string | number;
  events_type_id: string;
  paid: string;
  date_type: string;
  start_date: string | Date;
  end_date: string | Date;
  start_amount: string | number;
  end_amount: string | number;
  serial: string | number;
  order: string;
}
export interface TrxFilter {
  context: string;
  operation: string;
  affect: string;
  start_date: string;
  end_date: string;
  wallet_id: string | number | undefined;
}

export interface RevenuesData {
  average: number;
  id: number;
  network_profit: number;
  network_turnover: number;
  period: string;
  processed_at: string;
  total_play: number;
  type: string;
  context: string;
  play: number;
  win: number;
  profit: number;
  revenue: number;
  network_revenue: number;
  play_count: number;
  detail: boolean;
}

export interface Revenues {
  provigional_plan: ProvisionalPlan;
  revenues: RevenuesData[];
  wallet_type: WalletType;
  wallet: WalletProvisional;
}

export interface WalletProvisional {
  id: number;
  name: string;
}

export interface ProvisionalPlan {
  id: number;
  label: string;
  name: string;
  run_type: number;
  type: string;
  reveues: Revenues;
}

export interface Revenues {
  betting: RevenueData;
  casino: RevenueData;
  casinolive: RevenueData;
  poker: RevenueData;
  virtual: RevenueData;
}

export interface RevenueData {
  global: Global;
  ranges: Ranges[];
  rows: Rows[];
}
export interface Rows {
  label: string;
  from: number;
  profit: number;
}

export interface Ranges {
  bet: number;
  from: number;
  label: string;
  profit: number;
  to: number;
}

export interface Global {
  bet: number;
  profit: number;
  sharing: number;
}

export interface Total {
  bet: number;
  win: number;
  profit: number;
}
export interface Page {
  bet: number;
  win: number;
  profit: number;
}
export interface TransactionData {
  id: number;
  context: {
    id: number;
    label: string;
  };
  operation: string;
  affect: string;
  start: number;
  in: number;
  out: number;
  end: number;
  reference: string;
  description: string;
  note: string;
  created_at: string;
  game: string;
}

export interface BettingData {
  bet_amount: number;
  cancellable: boolean;
  cashout: boolean;
  closed_at: string;
  columns_count: number;
  coupon_state_id: string;
  coupon_type: CouponsType;
  coupon_state: CouponState;
  coupon_type_id: number;
  created_at: string;
  events_count: number;
  events_type_id: string;
  id: string;
  serial: string;
  parent: Parent;
  parent_id: number;
  wallet: WalletCoupon;
  wallet_id: number;
  win_amount: number;
  win_max: number;
  running: boolean;
  winner: boolean;
  void?: boolean;
  played?: boolean;
  payed?: boolean;
  payed_at?: string;
  skin: {
    name: string;
  };
}

export interface CouponState {
  id: string;
  label: string;
  name: string;
}

export interface CouponState {
  id: string;
  label: string;
  name: string;
}

export interface WalletCoupon {
  id: number;
  name: string;
}

export interface CouponsType {
  id: string;
  name: string;
  label: string;
}

export interface Parent {
  id: number;
  name: string;
}

export interface Receipt {
  content: string;
  filename: string;
}
export interface IChildrenResponsePayload {
  id: number;
  caller?: string;
  type?: string;
  children: Wallet[];
}

export interface ResponsePayloadRevenueChildren {
  id: number;
  children: Revenues[];
}

export interface RequestPayloadNewWallet {
  id: number;
  data: PayloadData;
  index: number;
}
export interface ResponsePayloadWalletToEdit {
  id: number;
  data: PayloadData;
}
export interface ResponsePayloadEditedWallet {
  childId: number;
  parentId: number;
  data: Wallet;
  index: number;
}
export interface RequestPayloadUser {
  id: number;
  data: UserPayloadData;
  index: number;
}
export interface ResponsePayloadUser {
  data: UserInfo;
  index: number;
}
export interface PayloadTransfer {
  start_wallet_id?: number;
  transaction_type_id?: number;
  amount?: number;
  note?: string;
}
export interface RequestWallet {
  action: string;
  id: number;
}
export interface RequestPayloadTransfer {
  walletId: number;
  data: PayloadTransfer;
}
export interface RequestPayloadTransactions {
  id?: number;
  params?: Record<string, number | undefined | FilterElement>;
}

export interface RequestPayloadBetting {
  id?: number;
  params?: Record<string, number | undefined | BetFilter>;
}
export interface RequestFilterSearch {
  id?: number;
  params?: Record<string, string>;
}
export interface RequestPayloadRevenue {
  id?: number;
  params?: Record<string, number | undefined | RevenueFilterElement>;
}

export interface RequestRevenueDetail {
  id?: number;
  params?: RevenueDetailFilter;
  wallet?: WalletProvisional;
}

export interface RequestPayloadProvisional {
  id?: number;
}
export interface IChildrenPayloadRequest {
  caller: string;
  type: string;
  id: number;
}
export interface IWalletPayloadRequest {
  action: string;
  id: number;
}

export interface MoveWalletSuccededPayload {
  data: Wallet[];
  movedWallet: number;
  newParentId: number;
  oldParentId: number;
}

export interface RevenueDetailProvider {
  name: string;
}

export interface RevenueDetails {
  play: number;
  win: number;
  profit: number;
  provider: RevenueDetailProvider;
}

const initialState: WalletState = {
  isFetching: false,
  isFetchingWallet: false,
  isFetchingChildren: false,
  isFetchingChildrenUpdate: false,
  isFetchingWalletToEdit: false,
  isFetchingAscendants: false,
  isFetchingSkinTrx: false,
  isFetchingNetworkTrx: false,
  isFetchingReceipt: false,
  isFetchingSkinBetting: false,
  isFetchingNetworkBetting: false,
  isFetchingProvisionalPlan: false,
  isFetchingRevenues: false,
  isFetchingRevenuesChildren: false,
  isFetchingMoveFunds: false,
  isFetchingWalletBonus: false,
  isFetchingWalletMove: false,

  networkWallet: undefined,
  networkWalletChildren: {},
  walletId: undefined,
  selectedWalletTypeId: undefined,
  walletToEdit: undefined,
  loginHistory: undefined,
  ancestors: undefined,

  skinTransactions: undefined,
  networkTransactions: undefined,
  receipt: undefined,

  skinBetting: undefined,
  networkBetting: undefined,
  provisional_plan: undefined,

  revenues: undefined,
  revenuesChildren: {},

  bettingWalletChildren: {},
  bettingWalletId: undefined,

  searchResults: undefined,
  isBalanceRechargeVisible: false,

  rowsPerPage: undefined,
  page: undefined,
  filters: undefined,

  playedCouponWalletId: undefined,

  isDesktopLayout: false,
  affectedWallets: undefined,
  walletBonus: undefined,

  revenueDetails: undefined,
};
export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    getWalletRequested: (
      state: WalletState,
      action: PayloadAction<RequestWallet>
    ) => {
      state.isFetchingWallet = true;
      action;
    },
    getWalletSucceded: (state: WalletState, action: PayloadAction<Wallet>) => {
      state.isFetchingWallet = false;
      state.networkWallet = action.payload;
    },
    getWalletFailed: (state: WalletState) => {
      state.isFetchingWallet = false;
    },
    getChildrenRequested: (
      state: WalletState,
      action: PayloadAction<IChildrenPayloadRequest>
    ) => {
      action.payload.type === "new"
        ? (state.isFetchingChildren = true)
        : (state.isFetchingChildrenUpdate = true);
    },
    getChildrenSucceded: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      const { children, id, caller, type }: IChildrenResponsePayload =
        JSON.parse(action.payload);
      type === "new"
        ? (state.isFetchingChildren = false)
        : (state.isFetchingChildrenUpdate = false);
      if (caller === "network") {
        state.networkWalletChildren = {
          ...state.networkWalletChildren,
          ...convertChildren(children, id),
        };
      } else if (caller === "betting") {
        state.bettingWalletChildren = {
          ...state.networkWalletChildren,
          ...convertChildren(children, id),
        };
      }
    },
    getChildrenFailed: (state: WalletState, action: PayloadAction<string>) => {
      action.payload === "new"
        ? (state.isFetchingChildren = false)
        : (state.isFetchingChildrenUpdate = false);
    },
    removeWalletChildren: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      const copy = { ...state.networkWalletChildren };
      delete copy[action.payload];
      state.networkWalletChildren = copy;
    },
    clearWalletChildren: (state: WalletState) => {
      state.networkWalletChildren = {};
    },

    createWalletRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadNewWallet>
    ) => {
      state.isFetching = true;
      action;
    },
    createWalletSucceded: (
      state: WalletState,
      action: PayloadAction<IChildrenResponsePayload>
    ) => {
      const { id, children } = action.payload;
      state.isFetching = false;
      state.networkWalletChildren[id]
        ? (state.networkWalletChildren = {
            ...state.networkWalletChildren,
            ...convertChildren(
              [...state.networkWalletChildren[id], ...children].reverse(),
              id
            ),
          })
        : (state.networkWalletChildren = {
            ...state.networkWalletChildren,
            ...convertChildren(children, id),
          });
    },
    createWalletFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getWalletToEditRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetchingWalletToEdit = true;
      action;
    },
    getWalletToEditSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadWalletToEdit>
    ) => {
      const { data } = action.payload;
      state.isFetchingWalletToEdit = false;
      state.walletToEdit = data;
    },
    getWalletToEditFailed: (state: WalletState) => {
      state.isFetchingWalletToEdit = false;
    },
    getLoginHistoryRequested: (
      state: WalletState,
      action: PayloadAction<any>
    ) => {
      state.isFetching = true;
      action;
    },
    getLoginHistorySucceded: (
      state: WalletState,
      action: PayloadAction<any>
    ) => {
      const { data } = action.payload;
      state.isFetching = false;
      state.loginHistory = data;
    },
    getLoginHistoryFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    editWalletRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadNewWallet>
    ) => {
      state.isFetching = true;
      action;
    },
    editWalletSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadEditedWallet>
    ) => {
      const { childId, parentId, data, index } = action.payload;
      state.isFetching = false;
      state.networkWalletChildren[parentId]
        ? (state.networkWalletChildren = {
            ...state.networkWalletChildren,
            ...editWallet(
              state.networkWalletChildren,
              parentId,
              childId,
              data,
              index
            ),
          })
        : (state.networkWallet = data);
    },
    editWalletFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getAscendantsRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetchingAscendants = true;
      action;
    },
    getAscendantsSucceded: (
      state: WalletState,
      action: PayloadAction<Wallet[]>
    ) => {
      state.isFetchingAscendants = false;
      state.ancestors = action.payload;
    },
    getAscendantsFailed: (state: WalletState) => {
      state.isFetchingAscendants = false;
    },
    saveSelectedWalletIds: (
      state: WalletState,
      action: PayloadAction<Record<string, number>>
    ) => {
      const { walletId, walletTypeId } = action.payload;
      walletId && (state.walletId = walletId);
      walletTypeId && (state.selectedWalletTypeId = walletTypeId);
    },
    saveBettingWalletId: (
      state: WalletState,
      action: PayloadAction<number | undefined>
    ) => {
      state.bettingWalletId = action.payload
        ? action.payload
        : state.networkWallet?.id;
    },
    clearSelectedWalletIds: (
      state: WalletState,
      action: PayloadAction<string>
    ) => {
      action.payload === "walletId" && (state.walletId = undefined);
      action.payload === "selectedWalletTypeId" &&
        (state.selectedWalletTypeId = undefined);
    },
    getSkinTransactionsRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadTransactions>
    ) => {
      state.isFetchingSkinTrx = true;
      action;
    },
    getSkinTransactionsSucceded: (
      state: WalletState,
      action: PayloadAction<Transactions>
    ) => {
      state.isFetchingSkinTrx = false;
      state.skinTransactions = action.payload;
    },
    getSkinTransactionsFailed: (state: WalletState) => {
      state.isFetchingSkinTrx = false;
    },
    getNetworkTransactionsRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadTransactions>
    ) => {
      state.isFetchingNetworkTrx = true;
      action;
    },
    getNetworkTransactionsSucceded: (
      state: WalletState,
      action: PayloadAction<Transactions>
    ) => {
      state.isFetchingNetworkTrx = false;
      state.networkTransactions = action.payload;
    },
    getNetworkTransactionsFailed: (state: WalletState) => {
      state.isFetchingNetworkTrx = false;
    },
    moveFundsRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadTransfer>
    ) => {
      state.isFetchingMoveFunds = true;
      action;
    },
    moveFundsSucceded: (
      state: WalletState,
      action: PayloadAction<Wallet[]>
    ) => {
      state.isFetchingMoveFunds = false;
      state.networkWalletChildren = moveFundsWallets(
        state.networkWalletChildren,
        action.payload
      );
      !state.networkWalletChildren[action.payload[0].parent_id] &&
        (state.networkWallet = action.payload[0]);
      state.isBalanceRechargeVisible &&
        (state.isBalanceRechargeVisible = false);
    },
    moveFundsFailed: (state: WalletState) => {
      state.isFetchingMoveFunds = false;
    },
    getReceiptRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetchingReceipt = false;
      action;
    },
    getReceiptSucceded: (
      state: WalletState,
      action: PayloadAction<Receipt>
    ) => {
      state.isFetchingReceipt = false;
      state.receipt = action.payload;
    },
    getReceiptFailed: (state: WalletState) => {
      state.isFetchingReceipt = false;
    },
    clearReceipt: (state: WalletState) => {
      state.receipt = undefined;
    },
    clearWalletState: (state: WalletState) => {
      state.networkWallet = undefined;
      state.networkWalletChildren = {};
      state.walletToEdit = undefined;
      state.skinTransactions = undefined;
      state.networkTransactions = undefined;
      state.selectedWalletTypeId = undefined;
      state.walletId = undefined;
      state.bettingWalletId = undefined;
      state.bettingWalletChildren = {};
      state.searchResults = undefined;
    },
    clearWalletToEdit: (state: WalletState) => {
      state.walletToEdit = undefined;
    },
    clearTransactions: (state: WalletState) => {
      state.skinTransactions = undefined;
      state.networkTransactions = undefined;
    },

    clearBetting: (state: WalletState) => {
      state.skinBetting = undefined;
      state.networkBetting = undefined;
    },

    clearRevenues: (state: WalletState) => {
      state.revenues = undefined;
    },
    clearRevenuesChildren: (state: WalletState) => {
      state.revenuesChildren = {};
    },

    clearProvigionalPlan: (state: WalletState) => {
      state.provisional_plan = undefined;
    },

    getFilteredSearchResultsRequested: (
      state: WalletState,
      action: PayloadAction<RequestFilterSearch>
    ) => {
      state.isFetching = true;
      action;
    },
    getFilteredSearchResultsSucceded: (
      state: WalletState,
      action: PayloadAction<Wallet[] | undefined>
    ) => {
      state.isFetching = false;
      state.searchResults = action.payload;
    },
    getFilteredSearchResultsFailed: (state: WalletState) => {
      state.isFetching = false;
    },

    getSkinBettingRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadBetting>
    ) => {
      state.isFetchingSkinBetting = true;
      action;
    },

    getSkinBettingSucceded: (
      state: WalletState,
      action: PayloadAction<Betting>
    ) => {
      state.isFetchingSkinBetting = false;
      state.skinBetting = action.payload;
    },
    getSkinBettingFailed: (state: WalletState) => {
      state.isFetchingSkinBetting = false;
    },
    getNetworkBettingRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadBetting>
    ) => {
      state.isFetchingNetworkBetting = true;
      action;
    },
    getNetworkBettingSucceded: (
      state: WalletState,
      action: PayloadAction<Betting>
    ) => {
      state.isFetchingNetworkBetting = false;
      state.networkBetting = action.payload;
    },
    getNewtorkBettingFailed: (state: WalletState) => {
      state.isFetchingNetworkBetting = false;
    },
    getProvisionalPlanRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadProvisional>
    ) => {
      state.isFetchingProvisionalPlan = true;
      action;
    },
    getProvisionalPlanSucceded: (
      state: WalletState,
      action: PayloadAction<ProvisionalPlan>
    ) => {
      state.isFetchingProvisionalPlan = false;
      state.provisional_plan = action.payload;
    },
    getProvisionalPlanFailed: (state: WalletState) => {
      state.isFetchingProvisionalPlan = false;
    },
    getRevenuesRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadRevenue>
    ) => {
      state.isFetchingRevenues = true;
      state.revenues && (state.revenues = undefined);
      state.revenuesChildren && (state.revenuesChildren = {});
      action;
    },
    getRevenuesSucceded: (
      state: WalletState,
      action: PayloadAction<Revenues>
    ) => {
      state.isFetchingRevenues = false;
      state.revenues = action.payload;
    },
    getRevenuesFailed: (state: WalletState) => {
      state.isFetchingRevenues = false;
    },

    getRevenuesChildrenRequested: (
      state: WalletState,
      action: PayloadAction<RequestPayloadRevenue>
    ) => {
      state.isFetchingRevenuesChildren = true;
      action;
    },
    getRevenuesChildrenSucceded: (
      state: WalletState,
      action: PayloadAction<ResponsePayloadRevenueChildren>
    ) => {
      const { children, id } = action.payload;
      state.isFetchingRevenuesChildren = false;
      state.revenuesChildren = {
        ...state.revenuesChildren,
        ...convertRevenueChildren(children, id),
      };
    },
    getRevenuesChildrenFailed: (state: WalletState) => {
      state.isFetchingRevenuesChildren = false;
    },
    clearSearchResult: (state: WalletState) => {
      state.searchResults = undefined;
      state.networkWallet = undefined;
      state.networkWalletChildren = {};
    },
    toggleBalanceRecharge: (state: WalletState) => {
      state.isBalanceRechargeVisible = !state.isBalanceRechargeVisible;
    },
    saveCouponParams: (state: WalletState, action: PayloadAction<any>) => {
      state.rowsPerPage = action.payload.rowsPerPage;
      state.page = action.payload.page;
      state.filters = action.payload.filters;
    },
    getWalletBonusRequested: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.isFetchingWalletBonus = true;
      action;
    },
    getWalletBonusSucceded: (
      state: WalletState,
      action: PayloadAction<WalletBonus>
    ) => {
      state.isFetchingWalletBonus = false;
      state.walletBonus = action.payload;
    },
    getWalletBonusFailed: (state: WalletState) => {
      state.isFetchingWalletBonus = false;
    },
    clearAncestors: (state: WalletState) => {
      state.ancestors = undefined;
    },
    savePlayedCouponWalletId: (
      state: WalletState,
      action: PayloadAction<number>
    ) => {
      state.playedCouponWalletId = action.payload;
    },
    toggleDesktopLayout: (state: WalletState) => {
      state.isDesktopLayout = !state.isDesktopLayout;
    },
    moveWalletRequested: (state: WalletState, action: PayloadAction<any>) => {
      state.isFetchingWalletMove = true;
      action;
    },
    moveWalletSucceded: (
      state: WalletState,
      action: PayloadAction<MoveWalletSuccededPayload>
    ) => {
      let i: any = undefined;
      action.payload.data.map((item: Wallet) => {
        state.networkWalletChildren[action.payload.oldParentId] =
          state.networkWalletChildren[action.payload.oldParentId].filter(
            (el) => el.id !== item.id
          );
        if (item.parent_id === 0) {
          state.networkWallet = item;
        }
        if (state.networkWalletChildren[item.parent_id]) {
          state.networkWalletChildren[item.parent_id].find(
            (element: Wallet, index: number) => {
              if (element.id === item.id) return (i = index);
            }
          );
          if (state.networkWalletChildren[item.parent_id][i].id === item.id) {
            state.networkWalletChildren[item.parent_id][i] = item;
          } else {
            state.networkWalletChildren[item.parent_id].push(item);
          }
        }
      });
      state.isFetchingWalletMove = false;
    },
    moveWalletFailed: (state: WalletState) => {
      state.isFetchingWalletMove = false;
    },
    getRevenueDetailsRequested: (
      state: WalletState,
      action: PayloadAction<RequestRevenueDetail>
    ) => {
      state.isFetching = true;
      action;
    },
    getRevenueDetailsSucceded: (
      state: WalletState,
      action: PayloadAction<RevenueDetails[]>
    ) => {
      state.isFetching = false;
      state.revenueDetails = action.payload;
    },
    getRevenueDetailsFailed: (state: WalletState) => {
      state.isFetching = false;
    },
    getWalletImpersonateRequested: (
      state: WalletState,
      action: PayloadAction<number | undefined>
    ) => {
      state.isFetching = true;
      action;
    },
    getWalletImpersonateSucceded: (
      state: WalletState,
      action: PayloadAction<any>
    ) => {
      state.isFetching = false;
      window
        .open(
          `https://${action.payload.url}/${action.payload.access_token}`,
          "_blank"
        )
        ?.focus();
    },
    getWalletImpersonateFailed: (state: WalletState) => {
      state.isFetching = false;
    },
  },
});
export const {
  getWalletRequested,
  getWalletSucceded,
  getWalletFailed,
  getChildrenRequested,
  getChildrenSucceded,
  getChildrenFailed,
  removeWalletChildren,
  createWalletRequested,
  createWalletSucceded,
  createWalletFailed,
  getWalletToEditRequested,
  getWalletToEditSucceded,
  getWalletToEditFailed,
  getLoginHistoryRequested,
  getLoginHistorySucceded,
  getLoginHistoryFailed,
  editWalletRequested,
  editWalletSucceded,
  editWalletFailed,
  getSkinTransactionsRequested,
  getSkinTransactionsSucceded,
  getSkinTransactionsFailed,
  getNetworkTransactionsRequested,
  getNetworkTransactionsSucceded,
  getNetworkTransactionsFailed,
  getReceiptRequested,
  getReceiptSucceded,
  getReceiptFailed,
  clearReceipt,
  clearWalletState,
  clearWalletToEdit,
  clearTransactions,
  clearBetting,
  clearRevenues,
  clearRevenuesChildren,
  clearProvigionalPlan,
  getAscendantsRequested,
  getAscendantsSucceded,
  getAscendantsFailed,
  moveFundsRequested,
  moveFundsSucceded,
  moveFundsFailed,
  saveSelectedWalletIds,
  clearSelectedWalletIds,
  getSkinBettingRequested,
  getSkinBettingSucceded,
  getSkinBettingFailed,
  getNetworkBettingRequested,
  getNetworkBettingSucceded,
  getNewtorkBettingFailed,
  getProvisionalPlanRequested,
  getProvisionalPlanSucceded,
  getProvisionalPlanFailed,
  getRevenuesRequested,
  getRevenuesSucceded,
  getRevenuesFailed,
  getFilteredSearchResultsRequested,
  getFilteredSearchResultsSucceded,
  getFilteredSearchResultsFailed,
  getRevenuesChildrenRequested,
  getRevenuesChildrenSucceded,
  getRevenuesChildrenFailed,
  saveBettingWalletId,
  clearSearchResult,
  toggleBalanceRecharge,
  clearWalletChildren,
  saveCouponParams,
  getWalletBonusRequested,
  getWalletBonusSucceded,
  getWalletBonusFailed,
  clearAncestors,
  savePlayedCouponWalletId,
  toggleDesktopLayout,
  moveWalletRequested,
  moveWalletSucceded,
  moveWalletFailed,
  getRevenueDetailsRequested,
  getRevenueDetailsSucceded,
  getRevenueDetailsFailed,
  getWalletImpersonateRequested,
  getWalletImpersonateSucceded,
  getWalletImpersonateFailed,
} = walletSlice.actions;

export default walletSlice.reducer;
