import { Revenues, Wallet } from "./walletSlice";

export const convertChildren = (
  children: Wallet[],
  parentId: number
): Record<string, Wallet[]> => {
  const obj: Record<string, Wallet[]> = {};
  obj[parentId] = children;
  return obj;
};

export const convertRevenueChildren = (
  children: Revenues[],
  parentId: number
): Record<string, Revenues[]> => {
  const obj: Record<string, Revenues[]> = {};
  obj[parentId] = children;
  return obj;
};

export const editWallet = (
  children: Record<string, Wallet[]>,
  parentId: number,
  childId: number,
  editedData: Wallet,
  index: number
): Record<string, Wallet[]> => {
  const newData = {
    ...children[parentId][index],
    ...editedData,
  };
  children[parentId].filter(function (value) {
    return value.id !== childId;
  });
  children[parentId][index] = newData;
  return convertChildren(children[parentId], parentId);
};

export const moveFundsWallets = (
  children: Record<string, Wallet[]>,
  response: Wallet[]
): Record<string, Wallet[]> => {
  let i: any = undefined;

  response.map((resp) => {
    if (children[resp.parent_id]) {
      children[resp.parent_id].find((value: Wallet, index: number) => {
        if (value.id === resp.id) return (i = index);
      });
      children[resp.parent_id][i] = resp;
    }
  });
  return children;
};
